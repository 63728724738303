.lang_header {
  background: none;
  border: none;
  gap: 4px;
}
.item_lan_header {
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
  text-transform: capitalize;
  color: #1b1c57;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media only screen and (max-width: 1091px) {
    color: #000000;

    // img {
    //   filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg)
    //     brightness(102%) contrast(102%);
    // }
  }
}
